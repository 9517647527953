<template>
    <div class="custom-service-container">
        <div class="custom-service-qrcode-container">
            <el-image
                :fit="'fill'"
                :src="customerServiceUrl"
                class="custom-service-qrcode"
                style="width: 300px; height: 300px"
            />
        </div>
        <ul class="custom-service-list">
            <li class="custom-service-detail">
                <span class="highlight">1 服务多元化</span>
                辅导范围覆盖多科目的论文写作、论文润色、写作辅导、考试等方面
            </li>
            <li class="custom-service-detail">
                <span class="highlight">2 专业写作辅导</span>
                来自顶尖院校的资深教师，提供切实有效的写作指导，帮助提高写作水平
            </li>
            <li class="custom-service-detail">
                <span class="highlight">3 个性化写作培养</span>
                根据学生实际情况，制定个性化写作计划，全方位提升写作实力
            </li>
            <li class="custom-service-detail">
                <span class="highlight">4 全程写作服务</span>
                从选题构思到终稿定稿，提供一站式专业写作辅导，高效完成作品
            </li>
        </ul>
    </div>
</template>

<script setup>
import customerServiceUrl from '@/assets/WechatIMG17.jpg'
import { checkToken } from '@/stores/authStore'
import { onMounted } from 'vue'

onMounted(() => {
    checkToken()
})
</script>

<style lang="scss" scoped>
.custom-service-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-around;

    .custom-service-container {
        height: 100%;
        display: flex;
        justify-content: center;

        .custom-service-qrcode {
            border-radius: 15px;
            background-color: #fff;
            //border: 3px solid rgba(255, 105, 135, 0.8); /* 渐变色 */
            border: 3px solid #87cefa; /* 天空蓝 */
        }
    }

    .custom-service-list {
        margin: 0;
        height: 300px;

        .custom-service-detail {
            margin-top: 40px;
            margin-bottom: 40px;
            display: flex;
            align-items: center;

            .highlight {
                background: linear-gradient(90deg, #ff6b6b, #ffb84d);
                color: white;
                padding: 3px 10px;
                border-radius: 12px;
                margin-right: 20px;
                font-weight: bold;
            }
        }
    }
}
</style>
