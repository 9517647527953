<template>
    <div class="update-pwd">
        <!--        <div class="navbar-wrapper">-->
        <!--            <Navbar />-->
        <!--        </div>-->
        <div class="update-pwd-container">
            <div class="update-pwd-main">
                <h2 class="title" style="margin-top: 0">更新密码</h2>

                <div class="update-pwd-form">
                    <el-input
                        v-model="updatePwd.account"
                        placeholder="手机号(+86)/邮箱"
                        style="width: 350px; height: 40px"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">账号</span>
                        </template>
                    </el-input>
                    <el-input
                        v-model="updatePwd.oldPassword"
                        placeholder="输入旧密码"
                        show-password
                        style="width: 350px; height: 40px"
                        type="password"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">旧密码</span>
                        </template>
                    </el-input>
                    <el-input
                        v-model="updatePwd.newPassword"
                        placeholder="输入新密码"
                        show-password
                        style="width: 350px; height: 40px"
                        type="password"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">新密码</span>
                        </template>
                    </el-input>
                    <el-input
                        v-model="updatePwd.confirmNewPassword"
                        placeholder="再次输入密码"
                        show-password
                        style="width: 350px; height: 40px"
                        type="password"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">确认密码</span>
                        </template>
                    </el-input>
                    <el-input
                        v-model="updatePwd.verificationCode"
                        placeholder="输入验证码"
                        style="width: 350px; height: 40px"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">验证码</span>
                        </template>
                        <template v-slot:suffix>
                            <el-button
                                :disabled="!canGetCode"
                                class="get-verify-code-btn"
                                link
                                @click="getVerificationCode"
                            >
                                {{ buttonText }}
                            </el-button>
                        </template>
                    </el-input>
                </div>
                <div>
                    <el-button
                        class="retrieve-pwd-confirm-button"
                        style="width: 150px"
                        @click="updatePwdButton"
                    >
                        更新密码
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'
import verifyPhone from '@/utils/verifyPhone'
import verifyMail from '@/utils/verifyMail'
import { removeToken } from '@/stores/authStore'

const updatePwd = reactive({
    account: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: ''
})

// 验证码时间
const canGetCode = ref(true)
const buttonText = ref('获取验证码')
let timer = null
const getVerificationCode = async () => {
    if (timer) return

    canGetCode.value = false
    let countdown = 60
    buttonText.value = `${countdown}秒`

    timer = setInterval(() => {
        countdown -= 1
        buttonText.value = `${countdown}秒`
        if (countdown <= 0) {
            clearInterval(timer)
            timer = null
            canGetCode.value = true
            buttonText.value = '获取验证码'
        }
    }, 1000)

    // console.log('发送验证码到手机')
    const requestParams = {
        phone: updatePwd.phone,
        type: 3
    }
    if (verifyPhone(updatePwd.account)) {
        requestParams.phone = updatePwd.account
    } else if (verifyMail(updatePwd.account)) {
        requestParams.email = updatePwd.account
    } else {
        alertService.showError('请输入正确的手机号或邮箱')
        return
    }
    try {
        await apiService.generateVerifyCode(requestParams)
        alertService.showSuccess('验证码已发送')
    } catch (error) {
        if (error.response?.data?.code === 401) {
            removeToken()
            alertService.showError('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

// 找回密码
const router = useRouter()
const updatePwdButton = async () => {
    // 校验确认密码
    if (updatePwd.newPassword !== updatePwd.confirmNewPassword) {
        alertService.showError('两次密码不一致')
        return
    }
    if (updatePwd.newPassword === updatePwd.oldPassword) {
        alertService.showError('新密码不能与旧密码相同')
        return
    }
    if (updatePwd.newPassword.length < 6) {
        alertService.showError('密码长度不能小于6位')
        return
    }
    if (updatePwd.account.length === 0) {
        alertService.showError('请输入账号')
        return
    }
    if (updatePwd.verificationCode.length !== 6) {
        alertService.showError('请输入正确格式验证码')
        return
    }
    // 参数
    const requestParams = {
        newPassword: updatePwd.newPassword,
        oldPassword: updatePwd.oldPassword,
        code: updatePwd.verificationCode
    }
    if (verifyPhone(updatePwd.account)) {
        requestParams.phone = updatePwd.account
    } else if (verifyMail(updatePwd.account)) {
        requestParams.email = updatePwd.account
    } else {
        alertService.showError('请输入正确的手机号或邮箱')
        return
    }
    try {
        await apiService.updatePassword(requestParams)
        // 跳转登录界面或显示成功信息
        alertService.showSuccess('密码修改成功')
        removeToken() // 修改登录状态
        router.push('/login')
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}
</script>

<style>
.update-pwd {
    display: flex;
    width: max(100%, 800px);
    height: max(100%, 600px);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .update-pwd-container {
        width: 600px;
        display: flex;
        flex-direction: column; /* 修改为列方向布局 */
        align-items: center;
        border-radius: 15px;
        border: 1px solid #002f5a;
        box-shadow: 0 0 10px 2px #002f5a;
        padding: 60px 30px;
        background-color: #fff;

        .update-pwd-main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .update-pwd-form {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .input-prepend {
                    display: inline-block;
                    width: 80px; /* 根据最长的前缀文字调整宽度 */
                    text-align: center;
                }

                .get-verify-code-btn {
                    color: #002f5a;
                    cursor: pointer;
                }
            }

            .retrieve-pwd-confirm-button {
                margin-top: 20px;
                background: #002f5a;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

.title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
}
</style>
