// src/stores/authStore.js
import { defineStore } from 'pinia'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'

const router = useRouter()
export const useAuthStore = defineStore('auth', {
    state: () => ({
        isLoggedIn: false
    }),
    actions: {
        refreshLoggedInStatus(isLoggin) {
            // const token = localStorage.getItem('token')
            // if (token) {
            //     this.isLoggedIn(true)
            // } else {
            //     this.isLoggedIn(false)
            // }
            this.isLoggedIn = isLoggin // 设置 isLoggedIn 的值
        }
    }
})

export function removeToken() {
    localStorage.removeItem('token')
    useAuthStore().refreshLoggedInStatus(false)
}

export function setToken(token) {
    localStorage.setItem('token', token)
    useAuthStore().refreshLoggedInStatus(true)
}

export function checkToken() {
    if (localStorage.getItem('token')) {
        useAuthStore().refreshLoggedInStatus(true)
        return true
    } else {
        alertService.showError('用户未登录')
        return false
    }
}
