import Swal from 'sweetalert2'

export const alertService = {
    showError(message) {
        Swal.fire({
            title: '错误',
            text: message,
            icon: 'error',
            confirmButtonText: '好的'
        })
    },
    showSuccess(message) {
        Swal.fire({
            title: '成功',
            text: message,
            icon: 'success',
            confirmButtonText: '好的'
        })
    },
    showInfo(message) {
        Swal.fire({
            title: '提示',
            text: message,
            icon: 'information',
            confirmButtonText: '好的'
        })
    }
}
