<template>
    <div class="home">
        <div class="deduplication-container">
            <div class="activity-column">
                <div class="activity-container">
                    <div class="activity-title">降AI使用指南</div>
                    <div class="activity-main">
                        <div class="activity-content">
                            【150词免费额度】新用户手机号注册即可获得
                        </div>
                        <div class="activity-content">【套餐购买】请点击左上角“充值”</div>
                        <div class="activity-content">【推荐使用浏览器】Chrome、Edge、Firefox</div>
                    </div>
                </div>
            </div>
            <div class="tip"></div>
            <div class="deduplication-content">
                <div class="deduplication-main">
                    <div class="deduplication-input">
                        <el-input
                            v-model="content.input"
                            class="input-left"
                            placeholder="请输入内容..."
                            resize="none"
                            style="width: 100%; font-family: 'Times New Roman'; font-size: 16px"
                            type="textarea"
                        >
                        </el-input>
                    </div>
                    <div class="deduplication-output">
                        <el-input
                            v-model="content.output"
                            class="input-right"
                            placeholder="降AI后的内容..."
                            resize="none"
                            style="width: 100%; font-family: 'Times New Roman'; font-size: 16px"
                            type="textarea"
                        >
                        </el-input>
                    </div>
                </div>
                <div class="deduplication-footer">
                    <div class="deduplication-footer">
                        <div class="deduplication-footer-left">
                            <div class="word-count" :class="{ 'text-red': isOverMaxWords }">
                                输入内容字数：{{ inputWordCount }} / {{ doWeightLossOnceMaxWords }}
                            </div>
                            <button
                                class="action-btn"
                                :disabled="isOverMaxWords"
                                @click="handleDeduplicationInput"
                            >
                                降AI
                            </button>
                        </div>
                        <div class="deduplication-footer-right">
                            <div class="word-count">输出内容字数：{{ outputWordCount }}</div>
                            <div class="quota">剩余额度：{{ quote }}</div>
                            <button class="copy-button" @click="copyOutputContent">一键复制</button>
                            <button
                                :disabled="isNotRemained"
                                class="show-all-btn"
                                @click="showAllContent"
                            >
                                显示全部内容
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// import Slider from '@/components/Slider.vue'
import { computed, onMounted, reactive, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { ElLoading, ElMessageBox, ElNotification } from 'element-plus'
import store from '@/stores/store.js'
import { checkToken, removeToken } from '@/stores/authStore'

const doWeightLossOnceMaxWords = 300

const content = reactive({
    input: '',
    output: ''
})
const warnOutputTitle = '额度警告'
const warnOutputContent = '现有额度已用完，若需显示完整版请购买套餐'
const warnInputTitle = '输入字数过少'
const warnInputContent = '单次输入字数应大于50词'
const showWarn = async (title, type, content) => {
    ElNotification({
        title: title,
        message: content,
        type: type,
        duration: 0
    })
}

const wordCount = (text) => {
    const inputText = text.trim()
    if (!inputText) return 0

    const words = inputText.match(/\b[\w']+\b/g) || []
    const wordCount = words.length

    const characters = inputText.match(/[\u4e00-\u9fa5]/g) || []
    const characterCount = characters.length

    return wordCount + characterCount
}

const inputWordCount = computed(() => wordCount(content.input))
const outputWordCount = ref(0)
const score = ref(0)
const quote = ref(0)
const isNotRemained = ref(true) // 用于控制“显示全部内容”按钮状态
const errorMessage = ref('')
const contentId = ref(0)
const isOverMaxWords = computed(() => inputWordCount.value > doWeightLossOnceMaxWords)
function copyOutputContent() {
    // 获取 el-input 组件的 DOM 元素
    const outputElement = document.querySelector('.deduplication-output .el-textarea__inner')
    if (outputElement) {
        // 选中输入框中的内容
        outputElement.select()
        // 执行浏览器的复制命令
        document.execCommand('copy')
        // 提示复制成功
        ElNotification({
            title: '复制成功',
            message: '输出内容已复制到剪贴板',
            type: 'success',
            duration: 3000
        })
    } else {
        // 如果没有找到元素，显示错误提示
        ElNotification({
            title: '复制失败',
            message: '无法找到输出内容',
            type: 'error',
            duration: 3000
        })
    }
}

const handleDeduplicationInput = async () => {
    if (inputWordCount.value < 50) {
        // errorMessage.value = ''
        await showWarn(warnInputTitle, 'error', warnInputContent)
        return
    }
    errorMessage.value = ''

    try {
        await ElMessageBox.confirm('是否进行降AI？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })

        const loading = ElLoading.service({
            lock: true,
            text: '正在处理，请稍候...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        const requestParams = {
            input: content.input
        }

        try {
            const responseContent = await apiService.weightLossTask(requestParams)
            content.output = responseContent.data.content.showContent
            quote.value = responseContent.data.content.balance
            isNotRemained.value = !responseContent.data.isRemained // 更新isRemained状态
            contentId.value = responseContent.data.content.id
            outputWordCount.value = responseContent.data.content.showNumber

            if (!isNotRemained.value) {
                await showWarn(warnOutputTitle, 'warning', warnOutputContent)
            }
        } catch (error) {
            if (error.response?.data?.code === 401) {
                removeToken()
                alertService.showError('用户未登录')
                // router.push('/login')
            } else {
                alertService.showError(error.response?.data?.message || '发生未知错误！')
            }
        } finally {
            loading.close()
        }
    } catch (error) {
        if (error !== 'cancel') {
            console.error('MessageBox error: ', error)
        }
    }
}

const showAllContent = async () => {
    try {
        await ElMessageBox.confirm('是否显示剩余内容？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })

        const loading = ElLoading.service({
            lock: true,
            text: '正在处理，请稍候...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        const requestParams = {
            id: contentId.value
        }

        try {
            const responseContent = await apiService.weightLossTaskRemainContent(requestParams)
            content.output = responseContent.data.content.showContent
            quote.value = responseContent.data.content.balance
            isNotRemained.value = !responseContent.data.isRemained // 更新isRemained状态
            contentId.value = responseContent.data.content.id
            score.value = responseContent.data.content.score // 更新得分
            outputWordCount.value = responseContent.data.content.showNumber
        } catch (error) {
            if (error.response?.data?.code === 401) {
                removeToken()
                alertService.showError('用户未登录')
                // router.push('/login')
            } else {
                alertService.showError(error.response?.data?.message || '发生未知错误！')
            }
        } finally {
            loading.close()
        }
    } catch (error) {
        if (error !== 'cancel') {
            console.error('MessageBox error: ', error)
        }
    }
}

async function fetchDataUserInfo() {
    const requestParams = {}
    try {
        const response = await apiService.listUserInfo(requestParams)

        quote.value = response.data.userInfo.balance
    } catch (error) {
        if (error.response?.data?.code === 401 || error.response?.data?.business_code === 10001) {
            removeToken()
            alertService.showError('用户未登录')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

onMounted(async () => {
    if (!checkToken()) {
        return
    }
    contentId.value = store.state.contentId
    if (contentId.value && contentId.value !== 0) {
        try {
            const responseContent = await apiService.listWeightLossContentById(contentId.value)
            // console.log(responseContent.data)
            content.input = responseContent.data.content.input
            content.output = responseContent.data.content.showContent
            quote.value = responseContent.data.content.balance

            contentId.value = responseContent.data.content.id
            score.value = responseContent.data.content.score // 更新得分
            outputWordCount.value = responseContent.data.content.showNumber
            const outputNumber = responseContent.data.content.outputNumber
            isNotRemained.value = true // 更新isRemained状态
            if (outputNumber !== outputWordCount.value) {
                isNotRemained.value = false
            }
        } catch (error) {
            if (error.response?.data?.code === 401) {
                alertService.showInfo('用户未登录')
                // router.push('/login')
            } else {
                alertService.showError(error.response?.data?.message || '发生未知错误！')
            }
        }
    } else {
        console.log('contentId.value', contentId.value)
        await fetchDataUserInfo()
    }
})
</script>

<style lang="scss">
.home {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .deduplication-container {
        display: flex;
        flex-direction: column; /* 修改为列方向布局 */
        width: 85%;
        height: 90%;

        .activity-column {
            padding: 0 2% 0 2%;
            width: 100%;
            background-color: #fff4e5; /* 背景颜色 */
            flex-direction: column; /* 修改为列方向布局 */
            margin-bottom: 40px;

            //.el-carousel__button {
            //    background-color: #ff9900;
            //}
            .el-carousel__ .tip-content {
                font-size: 16px;
                color: #995700; /* 字体颜色 */
            }

            .activity-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                .activity-reminder {
                    font-size: 20px;
                    color: #f80505; /* 字体颜色 */
                    text-align: center;
                }
                .activity-main {
                    display: flex;
                    flex-direction: column;

                    .activity-content {
                        font-size: 16px;
                        color: #995700; /* 字体颜色 */
                        text-align: left;
                    }
                }
            }

            //
            //.highlight {
            //    font-weight: bold;
            //    color: #ff6f00; /* 高亮颜色 */
            //}
        }

        .tip {
            font-size: 14px;
            color: gray;
        }

        .deduplication-content {
            width: 100%;
            height: 80%;
            display: flex;
            border: 1px solid #002f5a;
            box-shadow: 0 0 10px 2px #002f5a;

            align-items: center;
            padding: 2% 2% 1% 2%;
            flex-direction: column; /* 修改为列方向布局 */

            .deduplication-main {
                display: flex;
                width: 100% !important;
                height: 100% !important;
                justify-content: space-between;

                .deduplication-input,
                .deduplication-output {
                    width: 48%;

                    .el-textarea {
                        height: 100% !important;

                        .el-textarea__inner {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }

                .error-input {
                    color: red;
                    font-size: 12px;
                    display: block;
                    margin-top: 10px;
                }
            }

            /* 新增定位容器 */

            .deduplication-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100% !important;
                margin-top: 10px; /* 给顶部添加一些间距 */
                height: 5%;
                flex-direction: row; /* 修改为行方向布局 */

                .deduplication-footer-left,
                .deduplication-footer-right {
                    width: 48% !important;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .error-message {
                color: red;
            }

            .action-btn,
            .show-all-btn,
            .copy-button {
                right: 10px;
                background-color: #1890ff;
                color: white;
                border: none;
                padding: 5px 10px;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s ease;
            }

            .score {
                left: 60% !important;
            }

            .quota {
                left: 50% !important;
            }
            .action-btn:disabled,
            .show-all-btn:disabled,
            .show-all-btn:disabled {
                background-color: #d9d9d9;
                color: #a9a9a9;
                cursor: not-allowed;
            }
            .text-red {
                color: red;
            }
        }
    }
}
</style>
